import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountInfoService, AuthDataModel, SessionInfoService } from 'degarmo-lib';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(private router: Router, private sessionInfoService: SessionInfoService, private accountInfoService: AccountInfoService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const auth: AuthDataModel = this.sessionInfoService.getAuthenticationInformation();
    const account = this.accountInfoService.data.account;

    if (account && Object.keys(account).length === 0) {
      this.router.navigate(['/account/login']);
      return false;
    }

    // check for multi auth
    if (account && account.twoFactorAuthentication === true) return false;

    if (auth && auth.isLoggedIn) return true;
    else {
      this.router.navigate(['/account/login']);
      return false;
    }
  }
}
